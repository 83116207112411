.header {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  border-bottom: 1px solid var(--border-grey);
  width: 100%;
  height: 70px;
  transition: 0.2s ease-in-out;
  overflow-y: hidden;

  @media screen and (max-width: 767px) {
    background-color: var(--green);
    padding: 0 1rem;
    height: 48px;
  }

  &_active {
    height: 0 !important;
  }

  &_left {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &_location {
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        font-weight: 500;
        color: var(--text-grey);
        font-size: 13px;
        line-height: 16px;
        margin-left: 4px;
        text-transform: capitalize;
      }
    }
  }

  &_right {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 500px;
    height: 100%;

    &_login {
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: var(--text-grey);
        margin-left: 9.67px;
      }

      input {
        display: none;
      }
    }
  }
}