.visible {
  top: 0;
  transition: top 0.4s ease-out;
}

.hidden {
  top: -130px !important;
  transition: top 0.4s ease-out;
}


.header {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 1000;
  background-color: white;
}
