.modal {
  user-select: none;

  :global {
    .ant-modal-content {
      border-radius: 12px;
    }

    .ant-modal-header {
      border-radius: 12px 12px 0 0;
    }

    .ant-form-item {
      margin: 0;
    }

    .ant-input-affix-wrapper,
    input {
      border-radius: 4px;
    }

    .ant-select-selector {
      border-radius: 4px !important;
    }

  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.row {
  display: grid;
  grid-template-columns: 180px auto auto;
  align-items: center;
  gap: 8px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;

  :global {
    .ant-checkbox-wrapper {
      background-color: rgb(242, 244, 247);
      padding: 0 15px;
      height: 40px;
      border-radius: 4px;
      position: relative;
      overflow: hidden;

      span {
        width: 100%;
        height: 100%;
        transition: all 0.3s ease;

        &:hover {
          color: #00A389 !important;
        }

        &:last-child {
          position: relative;
          z-index: 10;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 16px;
        }
      }

      .ant-checkbox {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

      }

      .ant-checkbox-checked {
        &::after {
          display: none;
        }

        span {
          &:last-child {
            color: #00A389;
          }
        }
      }

      .ant-checkbox-input {
        width: 100%;
        height: 100%;
      }

      .ant-checkbox-inner {
        width: 100%;
        height: 100%;
        background-color: rgb(242, 244, 247);
        border: none;

        &::after {
          display: none;
        }
      }
    }

    .ant-checkbox-wrapper-checked {
      .ant-checkbox-inner {
        background-color: #e7fbf5;
        border: none;
      }

      span {
        &:last-child {
          color: #00A389;
        }
      }
    }
  }

}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;

  &_clear {
    display: flex !important;
    align-items: center !important;
    gap: 14px !important;
    cursor: pointer;
    border: 0 !important;
    box-shadow: none !important;

    &::after,
    &::before {
      display: none !important;
    }

    span {
      color: #F23535;
      font-size: 14px;
    }
  }

  &_btns {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
  }
}

.metro_select_option {
  padding: 12px 4px;
  border-bottom: 1px solid #EAECF0;

}

.no_border {
  border: none;
}