/* To solve Next.js issues source from https://github.com/Leaflet/Leaflet.markercluster/blob/master/dist/MarkerCluster.Default.css */

.marker-cluster-small,
.marker-cluster-medium,
.marker-cluster-large {
  background-color: var(--white);
}

.marker-cluster-small div,
.marker-cluster-medium div,
.marker-cluster-large div {
  background-color: var(--green);
}


/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
  background-color: rgb(181, 226, 140);
}

.leaflet-oldie .marker-cluster-small div {
  background-color: rgb(110, 204, 57);
}

.leaflet-oldie .marker-cluster-medium {
  background-color: rgb(241, 211, 87);
}

.leaflet-oldie .marker-cluster-medium div {
  background-color: rgb(240, 194, 12);
}

.leaflet-oldie .marker-cluster-large {
  background-color: rgb(253, 156, 115);
}

.leaflet-oldie .marker-cluster-large div {
  background-color: rgb(241, 128, 23);
}

.marker-cluster {
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 2rem !important;
  width: 2rem !important;
  padding: 2px;
  transition: 0.3s ease-in-out;
  outline: 5px solid transparent;
  box-shadow: 0px 6.63236px 9.34559px rgba(0, 0, 0, 0.136834),
    0px 2.75474px 3.88168px rgba(0, 0, 0, 0.105),
    0px 0.996336px 1.40393px rgba(0, 0, 0, 0.0731663);

  &:hover {
    outline-color: rgba(27, 129, 95, 0.25);
  }
}

.marker-cluster div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.marker-cluster span {
  font-weight: 500;
  font-size: 12px;
  color: var(--white);
}

.leaflet-marker-icon {
  box-shadow: 0px 6.63236px 9.34559px rgba(0, 0, 0, 0.136834),
    0px 2.75474px 3.88168px rgba(0, 0, 0, 0.105),
    0px 0.996336px 1.40393px rgba(0, 0, 0, 0.0731663);
  border-radius: 20px;
  transform: matrix(-1, 0, 0, 1, 0, 0);

  div {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50% !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease-in-out;
    outline: 5px solid transparent;
    background-color: var(--green) !important;
    box-shadow: 0px 6.63236px 9.34559px rgba(0, 0, 0, 0.136834),
      0px 2.75474px 3.88168px rgba(0, 0, 0, 0.105),
      0px 0.996336px 1.40393px rgba(0, 0, 0, 0.0731663);

    &:hover {
      outline-color: rgba(27, 129, 95, 0.25);
    }

    color: #fff;
  }
}