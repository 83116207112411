@import "./tailwind.css";

html {
  scroll-behavior: smooth;
  overscroll-behavior: none;
  /* Prevent elastic scrolling */
  -webkit-overflow-scrolling: touch;
}

:root {
  // text
  --text-grey: #959595;
  --text-dark-grey: #616161;
  --text-black: #2c2c2c;

  // btn
  --btn-light-green: #daf3eb;
  --btn-white-7: rgba(255, 255, 255, 0.7);
  --btn-white-9: rgba(255, 255, 255, 0.9);
  --btn-grey: #f5f5f5;
  --btn-extra-light-green: #f4f6fb;
  --btn-light-orange: rgba(255, 171, 85, 0.15);

  // icon
  --icon-grey: #b1b1b1;
  --icon-dark-grey: #7c7c7c;

  // border
  --border-grey: #ebebeb;

  // color
  --white: #ffffff;
  --blue: #086ada;
  --green: #00A389;
  --light-green: #2aaa7f;
  --dark-green: #095d41;
  --red: #f23535;
  --light-red: rgba(255, 12, 12, 0.2);
  --orange: #ffab55;
  --body-bg: #f2f4f6;
  --light-blue: #d1e2f3;
  --black-5: rgba(0, 0, 0, 0.5);
  --black-8: rgba(0, 0, 0, 0.8);
}

body {
  overscroll-behavior: none;
  /* Prevent elastic scrolling */
  -webkit-overflow-scrolling: touch;
  font-family: "Inter", sans-serif;
  @apply overflow-x-hidden scroll-smooth bg-[#f2f4f6] m-0 w-full #{!important};

  &::-webkit-scrollbar {
    @apply w-[0.2rem] h-[0.2rem] bg-transparent absolute #{!important};
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-[#00A389] rounded-lg;
  }

  // &.ant-scrolling-effect {
  //   width: calc(100% - rem(3.2)) !important;

  //   .header {
  //     width: calc(100% - rem(3.2)) !important;
  //   }
  // }

  .ant-modal-wrap {
    @apply overflow-hidden;
  }

  .ant-modal,
  .ant-drawer,
  .ant-collapse {
    transition: transform 0s ease-in-out, opacity 0s ease-in-out !important;
  }
}

html {
  @apply scroll-smooth #{!important};
}

.customInput {
  @apply rounded-l;

  &::placeholder,
  input::placeholder {
    @apply text-sm font-normal tracking-normal text-left text-[#959595];
  }
}

.placeholder {
  @apply text-sm font-normal tracking-normal text-left text-[#344054];
}

.mobileD {
  @media (min-width: 768px) {
    @apply hidden #{!important};
  }
}

.mobileTopFilter {
  &::-webkit-scrollbar {
    @apply w-[0.188rem] h-[0.188rem] bg-transparent;
  }
}

.mainFilter {
  &::-webkit-scrollbar {
    @apply w-[0.188rem] h-[0.188rem] bg-transparent;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-[#00A389];
  }
}

.ant-message-notice-content {
  @apply rounded-md;

  >div {
    @apply flex items-center gap-1;
  }
}

input, textarea, select {
  font-size: 16px !important;
}
