// GRID VIEW
.complex_card {
  @apply flex flex-col cursor-pointer;

  @media only screen and (max-width: 768px) {
    width: 100%;
    // height: 530px;
  }

  .complex_card_top {
    overflow: hidden;
    position: relative;
    background-color: var(--white);
    border-radius: 12px 12px 0 0;
    display: grid;
    width: 100%;
    border: 1px solid #ebebeb;
    border-bottom: none;

    .tags {
      display: flex;
      flex-wrap: wrap;
      position: absolute;
      margin: 12px;
      z-index: 2;
      top: 0;

    }

    .tag {
      display: flex;
      align-items: center;
      background-color: var(--white);
      padding: 4px 8px;
      border-radius: 15px;

      &.tag_primary {
        background-color: var(--green);

        span {
          color: var(--white);
        }
      }

      &:not(:last-child) {
        margin-right: 4px;
      }

      span {
        color: var(--text-dark-grey);
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
      }
    }

    .image_cont {
      height: 240px;
      position: relative;

      .image {
        opacity: 0;
        position: absolute;
        overflow: hidden;
        height: 240px;
        width: 100%;
        top: 0;
        left: 0;
        transition: 0.3s ease-in-out;

        &.active {
          opacity: 1;
        }

        span {
          height: 100% !important;
        }
      }
    }

    .image_slider {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;

      div {
        flex: 1;
      }
    }

    .slider_bullets {
      position: absolute;
      bottom: 8px;
      width: 100%;
      display: flex;
      padding: 0 1rem;
      justify-content: center;

      span {
        height: 3px;
        flex: 1;
        background: var(--icon-grey);
        border-radius: 13px;
        transition: 0.3s ease-in-out;

        &.active {
          background-color: var(--white);
        }

        &:not(:last-child) {
          margin-right: 4px;
        }
      }
    }
  }

  .complex_card_bottom {
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    border: 1px solid var(--border-grey);
    border-top: none;
    border-radius: 0 0 12px 12px;
    padding: 12px 1rem 1rem 1rem;
    flex: 1;
    position: relative;
    justify-content: space-between;

    .top {
      display: flex;

      .tags {
        display: flex;
        flex-wrap: wrap;

        .tag {
          display: flex;
          align-items: center;
          background-color: var(--btn-grey);
          border-radius: 15px;
          padding: 4px 6px;

          &:not(:last-child) {
            margin-right: 4px;
          }

          span {
            color: var(--text-dark-grey);
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
          }
        }
      }

      .like {
        display: flex;
        align-items: center;
        margin-left: auto;

        svg {
          cursor: pointer;
        }
      }
    }

    .name {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      column-gap: 1rem;

      h3 {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        margin: 0;
      }

      .like {
        display: flex;
        align-items: center;
        margin-left: auto;

        svg {
          cursor: pointer;
        }
      }
    }

    .address {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #475467;
      margin-top: 0;
    }

    .price_cont {
      margin-bottom: 24px;

      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: var(--green);
        margin-top: 0;
      }

      span {
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: var(--green);
      }
    }

    .builder {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: var(--blue);
      margin-bottom: 14px;
      margin-top: 0;
    }



    .call {
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      background-color: var(--color-active-green, #00A389);
      height: 40px;

      span {
        color: #fff !important;
        font-size: 13.5px;
        font-weight: 500;
      }
    }



  }
}

// LIST VIEW
.complex_card.list {
  display: flex;
  cursor: pointer;
  width: 400px;

  @media screen and (max-width: 1024px) {
    width: auto;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    // height: 530px;
  }

  .complex_card_top {
    overflow: hidden;
    position: relative;
    border-radius: 12px 0 0 12px;
    width: 350px;
    background-color: var(--white);

    .image_cont {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 1px;
      column-gap: 1px;

      .image {
        overflow: hidden;
        height: 64px;
        width: 100%;
        position: relative;
        transition: 0.3s ease-in-out;

        &.active {
          opacity: 1;
        }

        span {
          height: 100% !important;
        }

        .count_of_images {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          background-color: var(--black-5);

          p {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: var(--white);
            margin: 0;
          }
        }
      }

      .image0 {
        grid-column-start: 1;
        grid-column-end: 4;
        height: 350px;
      }
    }
  }

  .complex_card_bottom {
    display: flex;
    justify-content: space-between;
    background-color: var(--white);
    border: 1px solid var(--border-grey);
    border-left: none;
    border-radius: 0 12px 12px 0;
    padding: 2rem 2rem 2rem 1.5rem;
    column-gap: 2rem;
    flex: 1;
  }
}

.container {
  display: grid;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.mySwiper {
  height: 218px !important;
  width: 100%;
  display: none;
  position: relative;

  &_card {
    width: 100%;
    height: 100%;
    background: #000;
    position: absolute;
    opacity: 0.1;
    top: 0;
    left: 0;
    z-index: 100;
  }

  @media screen and (max-width: 768px) {
    display: block;
  }

  :global {
    .swiper-slide {
      height: 100%;
      overflow: hidden;
      // opacity: 0.9;
    }


    .swiper-pagination-bullets {
      display: flex;
      align-items: center;
      padding: 0 20px;
      bottom: 7px !important;
    }

    .swiper-pagination-bullet {
      width: 100%;
      height: 3px;
      background: #b1b1b1;
      opacity: 1;
      border-radius: 0;
    }

    .swiper-pagination-bullet-active {
      background: #fff;
    }
  }
}