.container {
  :global {
    .ant-modal-content {
      border-radius: 12px;
      overflow: hidden;
    }
  }

  &_wrapper {
    display: flex;
    align-items: center;
    gap: 24px;

    &_item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 8px;

      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        color: #444444;
      }
    }
  }

  &_copy {
    display: flex;
    align-items: center;
    border-radius: 12px;
    padding: 10px 8px 10px 16px;
    border: 1px solid #E5E7EB;
    margin-top: 16px;
    gap: 10px;

    button {
      border-radius: 48px;

      @media only screen and (max-width: 768px) {
        width: 85px !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        font-size: 12px !important;
      }
    }
  }
}