.complex {
  display: flex;
  justify-content: center;
  position: relative;
  top: -160px;
  z-index: 4;
  margin-bottom: -160px !important;

  @media screen and (max-width: 767px) {
    position: initial;
    margin-bottom: 16px !important;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &_container {
    width: 100%;
    display: flex;
    column-gap: 20px;
  }

  &_left {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    width: calc(100% - 380px);
    border-radius: 12px;

    @media screen and (max-width: 991px) {
      width: 100%;
      row-gap: 20px;
    }

    &_info {
      display: flex;
      flex-direction: column;
      // padding: 24px;
      border-radius: 12px;
      background: var(--white);

      @media screen and (max-width: 767px) {
        background: transparent;
        gap: 24px;
        padding: 0;
      }

      .name {
        font-weight: 600;
        font-size: 24px;
        line-height: 39px;
        color: var(--text-black);
        margin-bottom: 4px;

        span {
          color: #00A389;
        }

        @media screen and (max-width: 767px) {
          //display: none;
          margin-bottom: 0;
          font-size: 20px;
        }
      }

      .name1 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: var(--text-black);
        margin-bottom: 16px;
        display: none;

        @media screen and (max-width: 767px) {
          display: block;
        }
      }

      .deadline {
        font-weight: 400;
        font-size: 1rem;
        line-height: 19px;
        color: var(--text-grey);
        margin-bottom: 24px;

        @media screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 19px;
        }
      }

      .address {
        font-weight: 400;
        font-size: 1rem;
        line-height: 19px;
        color: var(--text-black);
        margin-bottom: 12px;
        text-transform: capitalize;

        @media screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 17px;
        }
      }

      a {
        display: block;
        font-weight: 400;
        font-size: 1rem;
        line-height: 19px;
        text-decoration-line: underline;
        //color: var(--green);
        margin-bottom: 24px;
      }

      .image_swiper {
        position: relative;
        display: flex;
        height: 448px;
        border-radius: 8px;
        overflow: hidden;
        user-select: none;
        margin-bottom: 12px;

        @media screen and (max-width: 767px) {
          height: 224px;
        }

        .swiper {
          width: 100%;
          height: 100%;

          .swiper_slide {
            cursor: pointer;

            span {
              width: 100% !important;
              height: 100% !important;
            }
          }

          &:hover {
            .navigation_cont {
              opacity: 1;
            }
          }

          :global {

            .swiper-button-prev,
            .swiper-button-next {
              transition: 0.3s ease-in-out;
            }

            .swiper-pagination {
              width: unset;
              background-color: var(--icon-dark-grey);
              color: var(--white);
              font-weight: 600;
              font-size: 13px;
              line-height: 16px;
              padding: 6px 12px;
              border-radius: 24px;
              bottom: 1rem;
              left: 50%;
              transform: translateX(-50%);
              transition: 0.3s ease-in-out;
              user-select: none;

              span {
                color: var(--white);
                font-weight: 600;
                font-size: 13px;
                line-height: 16px;
              }
            }
          }
        }

        .navigation_cont {
          display: flex;
          justify-content: space-between;
          position: absolute;
          z-index: 1;
          width: 100%;
          top: 50%;
          transform: translateY(-50%);
          opacity: 0;
          transition: 0.3s ease-in-out;
          padding: 0 1rem;
          pointer-events: none;

          @media screen and (max-width: 767px) {
            opacity: 1;
          }

          .prev,
          .next {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 2rem;
            width: 2rem;
            background-color: var(--white);
            border-radius: 50%;
            cursor: pointer;
            pointer-events: auto;

            svg path {
              stroke: var(--icon-dark-grey);
            }
          }

          :global {
            .swiper-button-disabled {
              opacity: 0.5;
            }
          }
        }
      }

      .all_images {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        column-gap: 12px;
        height: 80px;

        @media screen and (max-width: 991px) {
          margin-bottom: 10px;
        }

        @media screen and (max-width: 767px) {
          grid-template-columns: repeat(3, 1fr);
        }

        .image {
          flex: 1;
          border: 2px solid transparent;
          border-radius: 8px;
          overflow: hidden;
          cursor: pointer;
          transition: 0.3s ease-in-out;
          position: relative;

          span {
            height: 100% !important;
          }

          &.active {
            border-color: var(--green);
          }

          .count_of_images {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            background-color: var(--black-5);

            p {
              font-weight: 600;
              font-size: 20px;
              line-height: 24px;
              color: var(--white);
              margin: 0;
            }
          }
        }
      }

      .mobile_info {
        display: none;

        @media screen and (max-width: 991px) {
          display: block;
        }

        .price_cont {
          display: flex;
          flex-direction: column;
          row-gap: 24px;
          margin-bottom: 16px;

          .price_area {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: var(--blue);
            margin-bottom: 0;
          }

          .price_overall {
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: var(--text-black);
            margin-bottom: 0;

            @media screen and (max-width: 767px) {
              font-size: 18px;
            }
          }
        }

        .buttons_cont {
          width: 100%;
          display: flex;
          flex-direction: column;
          position: relative;
          row-gap: 12px;


          button {
            background: var(--color-active-green, #00A389) !important;
            color: #ffffff;
            padding: 0 10px;
            border-radius: 16px;
            position: relative;
            font-size: 16px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0;
            height: 51px;

            &::after {
              content: '';
              position: absolute;
              top: 0;
              left: -20px;
              width: 40px;
              height: 100%;
              transform: skew(-30deg);
              background: linear-gradient(to right,
                  #ffffff10,
                  #ffffff20,
                  #ffffff30,
                  #ffffff40,
                  #ffffff50,
                  #ffffff60,
                  #ffffff70,
                  #ffffff80,
                  #ffffff70,
                  #ffffff60,
                  #ffffff50,
                  #ffffff40,
                  #ffffff30,
                  #ffffff20,
                  #ffffff10);
              animation-name: animNew;
              animation-duration: 2s;
              animation-iteration-count: infinite;
              animation-timing-function: linear;
              animation-play-state: running;
            }

            @keyframes animNew {
              from {
                left: -200px;
              }

              to {
                left: 150%;
              }
            }
          }
        }
      }
    }

    &_apartment {
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      @media screen and (max-width: 767px) {
        row-gap: 20px;
      }

      &_top {
        background: var(--white);
        padding: 16px;
        border-radius: 12px;

        h2 {
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          margin-bottom: 0;
          color: #2C2C2C;
          display: flex;
          align-items: center;
          gap: 6px;

          @media only screen and (max-width: 768px) {
            font-size: 20px;
          }

          // div {
          //   padding: 6px 8px;
          //   border-radius: 8px;
          //   background-color: #00A389;
          //   cursor: pointer;

          //   svg {
          //     path {
          //       fill: #fff;
          //     }
          //   }
          // }

          @media screen and (max-width: 767px) {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
          }

          span {
            color: var(--green);
          }
        }
      }

      &_bottom {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        margin-top: 16px;

        @media screen and (max-width: 767px) {
          margin: 16px;
        }

        .collapse {
          border: none;
          background: transparent;

          &_panel {
            border: none !important;

            &:not(&:last-child) {
              border-bottom: none;
              margin-bottom: 16px;

              .ant-collapse-item {
                border-bottom: none;
              }
            }
          }

          :global {
            .ant-collapse-header {
              border-radius: 12px !important;
              padding: 0 !important;
              background: var(--white);
            }

            .anticon {
              right: 24px !important;

              svg {
                width: 16px !important;
                height: 16px !important;
                fill: var(--text-grey);
              }
            }

            .ant-collapse-content-box,
            .ant-collapse-content,
            .ant-collapse-content-active {
              background: transparent !important;
              border: 0;
            }

            .ant-collapse-content-box {
              padding: 0;
            }
          }
        }

        .apartment_card {
          width: 100%;
          display: flex;
          align-items: center;
          column-gap: 2rem;

          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 64px;
            height: 64px;
            background: #f8f8f8;
            border-radius: 50%;
          }

          .info {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: 2rem;

            @media screen and (max-width: 767px) {
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              margin-right: 1rem;
              row-gap: 12px;
            }

            &_left {
              display: flex;
              flex-direction: column;
              row-gap: 12px;

              div {
                display: flex;
                margin: 0;
                font-weight: 600;
                font-size: 1rem;
                color: var(--text-black);
                line-height: 19px;

                @media screen and (max-width: 767px) {
                  flex-direction: column;

                  &:first-child {
                    row-gap: 12px;
                  }
                }

                p {
                  margin: 0;
                }

                span {
                  color: var(--text-grey);
                }
              }
            }

            &_right {
              font-weight: 400;
              font-size: 1rem;
              color: var(--green);
              line-height: 19px;
              margin: 0;
            }
          }
        }
      }
    }

    &_features {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      background: var(--white);
      padding: 24px;
      border-radius: 12px;

      @media screen and (max-width: 767px) {
        row-gap: 20px;
      }

      @media screen and (max-width: 576px) {
        padding: 16px;
      }

      .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 39px;
        color: var(--black);
        margin: 0;

        @media screen and (max-width: 767px) {
          font-size: 20px;
          line-height: 22px;
        }
      }

      .body {
        display: grid;
        position: relative;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 40px;
        row-gap: 24px;
        border-radius: 12px;


        @media screen and (max-width: 767px) {
          grid-template-columns: 1fr;
          padding: 0;
        }

        .item {
          display: flex;
          align-items: center;
          gap: 10px;

          span {
            display: flex;
            height: 12px;
            width: 12px;
            background-color: var(--green);
            border-radius: 50%;
          }

          p {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: var(--text-dark-grey);
            margin: 0;
          }
        }
      }
    }

    &_about_complex {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      background: var(--white);
      border-radius: 12px;
      padding: 24px;

      @media screen and (max-width: 767px) {
        row-gap: 16px;
        padding: 16px;
      }

      .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 39px;
        color: #2C2C2C;
        margin: 0;

        @media screen and (max-width: 767px) {
          font-size: 20px;
          line-height: 22px;
        }
      }

      .body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        p {
          font-weight: 400;
          font-size: 1rem;
          line-height: 150%;
          color: var(--text-black);
          white-space: pre-wrap;
          margin: 0;

          @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
    }

    &_map {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      background: var(--white);
      padding: 24px;
      border-radius: 12px;
      position: relative;

      @media screen and (max-width: 767px) {
        row-gap: 16px;
        padding: 16px;
      }

      .body {
        overflow: hidden;
        position: relative;

        ::-webkit-scrollbar {
          width: 3.2px;
          height: 3.2px;
          background: transparent;
          position: absolute;
        }

        ::-webkit-scrollbar-thumb {
          background-color: var(--green);
          border-radius: 8px;
        }

        @media screen and (max-width: 767px) {
          padding: 0;
        }


        .map_container {
          @apply z-[1] overflow-hidden rounded-[8px] h-[468px] border border-solid border-[#EBEBEB];


          @media screen and (max-width: 767px) {
            height: 288px;
          }

          :global {
            .leaflet-control-attribution {
              display: none !important;
            }
          }
        }

        :global {
          .leaflet-left {
            right: 10px;
            left: auto;
          }
        }
      }



      .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 39px;
        color: var(--black);
        margin: 0;

        @media screen and (max-width: 767px) {
          font-size: 20px;
          line-height: 22px;
        }
      }
    }

    &_about {
      display: flex;
      flex-direction: column;
      row-gap: 40px;

      @media screen and (max-width: 767px) {
        row-gap: 20px;
      }

      .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 39px;
        color: var(--black);
        margin: 0;

        @media screen and (max-width: 767px) {
          font-size: 20px;
          line-height: 22px;
        }
      }

      .body {
        background: var(--white);
        padding: 24px;
        border-radius: 12px;

        p {
          font-weight: 400;
          font-size: 1rem;
          line-height: 150%;
          color: var(--text-black);
          margin-bottom: 24px;

          @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 17px;
          }
        }

        button {
          display: flex;
          align-items: center;
          padding: 0 12px;
          height: 51px;
          border: 1px solid var(--border-grey);
          border-radius: 12px;

          span {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: var(--text-dark-grey);
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          }

          &:hover,
          &:focus {
            span {
              color: var(--green);
            }
          }
        }
      }
    }
  }

  &_right {
    height: max-content;
    transition: height 0.5s ease;
    padding: 24px;
    border-radius: 12px;
    background: var(--white);
    width: 360px;
    position: sticky;
    top: 100px;

    @media screen and (max-width: 991px) {
      display: none;
    }




    &_address {
      margin-bottom: 20px;

      p {
        font-weight: 500;
        font-size: 13px;
        line-height: 1rem;
        color: var(--green);
        margin-bottom: 12px;
        display: flex;
        align-items: center;

        svg {
          margin-right: 4px;
        }
      }

      span {
        font-weight: 400;
        font-size: 13px;
        line-height: 1rem;
        color: var(--text-grey);
      }
    }

    &_price {
      margin-bottom: 20px;

      &_first {
        h4 {
          font-weight: 400;
          font-size: 1rem;
          line-height: 19px;
          color: var(--blue);
          margin-bottom: 4px;
        }
      }

      &_second {
        h2 {
          font-weight: 600;
          font-size: 24px;
          color: var(--text-black);
          line-height: 29px;
          margin-bottom: 0;

          &:not(:last-child) {
            margin-bottom: 4px;
          }
        }
      }
    }

    &_logo {

      &_img {
        display: flex;
        align-items: center;
        // justify-content: center;
        gap: 12px;
      }

      &_discount {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        margin-bottom: 20px;

        span {
          background: var(--btn-grey);
          border-radius: 15px;
          padding: 4px 6px;
          font-weight: 400;
          font-size: 13px;
          line-height: 1rem;
          color: var(--text-dark-grey);
        }
      }

      p {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin: 0;

        a {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          color: #2C2C2C;
          margin: 0;
        }

        span {
          color: #959595;
          font-size: 14px;
          line-height: 17px;
          font-weight: 400;
        }
      }
    }

    &_contact {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      row-gap: 12px;

      a {
        width: 100% !important;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: none;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        width: 100%;
        padding: 0;
        height: 51px;
        border: none;
        border-radius: 12px;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

        span {
          font-weight: 500;
          font-size: 16px;
          color: var(--white);
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }

        &.phone {
          background-color: #00A389;

          &:hover {
            background-color: var(--btn-light-orange);

            span {
              color: var(--orange);
            }
          }
        }

      }
    }
  }
}

.panel {
  display: flex;
  flex-direction: column;
  background: transparent !important;
  padding-top: 8px;
  row-gap: 8px;

  &_card {
    display: flex;
    align-items: center;
    column-gap: 48px;
    background: var(--white);
    border: 1px solid var(--border-grey);
    border-radius: 12px;
    padding: 12px 16px;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      column-gap: 20px;
    }

    .image {
      position: relative;
      width: 64px;
      height: 64px;

      @media screen and (max-width: 767px) {
        width: 80px;
        height: 80px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .info {
      flex: 1;
      display: flex;
      align-items: center;
      column-gap: 1rem;
      justify-content: space-between;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 20px;
      }

      &_left {
        flex: 1;
        display: flex;
        align-items: center;
        column-gap: 1rem;

        @media screen and (max-width: 767px) {
          width: 100%;
        }

        .item {
          display: flex;
          flex-direction: column;
          width: calc(100% / 3);

          p {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--text-black);
            margin: 0;
          }

          span {
            font-weight: 400;
            font-size: 13px;
            line-height: 24px;
            letter-spacing: 0.002em;
            color: var(--text-grey);
          }
        }
      }

      &_right {
        display: flex;
        flex-direction: column;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: var(--text-black);
          margin: 0;
        }

        span {
          font-weight: 400;
          font-size: 13px;
          line-height: 24px;
          letter-spacing: 0.002em;
          color: var(--text-grey);
        }
      }
    }
  }

  &_skeleton {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    background: var(--white);
    border: 1px solid var(--border-grey);
    border-radius: 12px;
    margin-bottom: 8px;
    padding: 12px 16px;

    :global {
      .ant-skeleton-image {
        width: 64px;
        height: 64px;
      }
    }
  }
}

.beta {
  background: var(--color-active-green, #00A389) !important;
  color: #ffffff;
  padding: 0 10px;
  border-radius: 16px;
  position: relative;
  font-size: 16px;
  overflow: hidden;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  width: 100%;
  height: 50px;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    height: 41px;
    border-radius: 10px;
  }

  div {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    width: 40px;
    height: 100%;
    transform: skew(-30deg);
    background: linear-gradient(to right,
        #ffffff10,
        #ffffff20,
        #ffffff30,
        #ffffff40,
        #ffffff50,
        #ffffff60,
        #ffffff70,
        #ffffff80,
        #ffffff70,
        #ffffff60,
        #ffffff50,
        #ffffff40,
        #ffffff30,
        #ffffff20,
        #ffffff10);
    animation-name: animNew;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-play-state: running;
  }

  @keyframes animNew {
    from {
      left: -200px;
    }

    to {
      left: 150%;
    }
  }
}

.map_amenity {
  display: flex;
  // justify-content: center;
  gap: 16px;
  align-items: flex-start;
  position: absolute;
  flex-wrap: wrap;

  @media screen and (max-width: 576px) {
    flex-wrap: nowrap;
    overflow-y: scroll;
    margin-top: 16px;
    padding-bottom: 6px;
    gap: 16px;
    justify-content: flex-start;

    &_item {
      padding: 4px 12px 4px 5px;
    }
  }

  .food {
    transition: .1s;
    border-color: #1570EF !important;

    svg {
      circle {
        fill: #D1E9FF;
      }

      path {
        fill: #1570EF;
      }
    }

    &:hover {
      border-color: #1570EF !important;

      svg {
        circle {
          fill: #D1E9FF;
        }

        path {
          fill: #1570EF;
        }
      }
    }
  }

  .park {
    transition: .1s;

    border-color: #DC6803 !important;

    svg {
      circle {
        fill: #FEF0C7;
      }

      path {
        fill: #DC6803;
      }
    }

    &:hover {
      border-color: #DC6803 !important;

      svg {
        circle {
          fill: #FEF0C7;
        }

        path {
          fill: #DC6803;
        }
      }
    }
  }

  .schools {
    transition: .1s;
    border-color: #7839EE !important;

    svg {
      circle {
        fill: #ECE9FE;
      }

      path {
        fill: #7839EE;
      }
    }

    &:hover {
      border-color: #7839EE !important;

      svg {
        circle {
          fill: #ECE9FE;
        }

        path {
          fill: #7839EE;
        }
      }
    }
  }

  .kindergarden {
    transition: .1s;
    border-color: #039855 !important;

    svg {
      circle {
        fill: #D1FADF;
      }

      path {
        fill: #12B76A;
      }
    }

    &:hover {
      border-color: #039855 !important;

      svg {
        circle {
          fill: #D1FADF;
        }

        path {
          fill: #12B76A;
        }
      }
    }
  }

  .hospitals {
    transition: .1s;

    border-color: #CA8504 !important;

    svg {
      circle {
        fill: #FEF7C3;
      }

      path {
        fill: #CA8504;
      }
    }

    &:hover {
      border-color: #CA8504 !important;

      svg {
        circle {
          fill: #FEF7C3;
        }

        path {
          fill: #CA8504;
        }
      }
    }
  }

  .mall {
    transition: .1s;

    border-color:
      #DD2590 !important;

    svg {
      circle {
        fill:
          #FCE7F6;
      }

      path {
        fill: #CA8504;
      }
    }

    &:hover {
      border-color:
        #DD2590 !important;

      svg {
        circle {
          fill:
            #FCE7F6;
        }

        path {
          fill: #DD2590;
        }
      }
    }
  }

  .fullsreen_item {
    box-shadow: 0px 2px 4px -2px #1018280F;
    box-shadow: 0px 4px 8px -2px #1018281A;
    @apply text-base #{!important};

  }

  .item,
  .fullsreen_item {
    @apply flex items-center gap-[6px] rounded-[10px] h-9 text-sm font-medium text-[#2C2C2C] px-3 py-2;

    >svg {
      width: 20px;
      height: 20px;
    }



    &:hover {
      color: #000 !important;
    }

    // @media (max-width: 570px) {
    //   font-size: 10px;
    // }

    // @media (max-width: 415px) {
    //   font-size: 9px;
    //   padding: 5px !important;
    // }
  }
}

.mapIcon {
  box-shadow: none !important;
}

.content {
  display: flex;
  flex-direction: column-reverse;
  gap: 24px;
}

.about {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);

    &_item {
      width: 100%;
    }
  }

  &_item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px 16px;
    border-radius: 16px;
    background-color: #F5F5F5;

    &_card {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      width: 48px;
      height: 48px;
      border-radius: 12px;
    }

    span {
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      color: #9A9A9A;
    }

    p {
      margin: 0;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: #383838;
    }
  }
}

.navigation {
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 3px solid #CFCFCF;
  height: 30px;

  a {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    color: #9A9A9A;
    position: relative;
    padding: 0 12px;
    height: 100%;

    div {
      width: 100%;
      height: 0px;
      background-color: #000;
      position: absolute;
      bottom: -3px;
      left: 0;
    }

    &::after {
      transition: all 0.4s;
      position: absolute;
      bottom: -3px;
      left: 0;
      content: '';
      width: 0;
      height: 2px;
      background-color: #000;
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.filter {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 24px;
  background-color: #fff;

  @media screen and (max-width: 767px) {
    flex-direction: column;

    div {
      width: 100%;
    }
  }

  div {
    flex: 1 1;

    :global {
      .ant-select {
        width: 100%;

        .ant-select-selector {
          border-radius: 8px;
          height: 39px;

          .ant-select-selection-placeholder {
            line-height: 35px;
          }
        }
      }
    }
  }

  &_right {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;

    &_item {
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #EBEBEB;
      cursor: pointer;
      border-radius: 8px;
      padding: 0 16px;

      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #616161;
      }
    }
  }
}

.builderInfo {
  display: flex;
  align-items: center;
  gap: 12px;

  &_left {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 44px;
    border-radius: 12px;
    background-color: #F5F5F5;
  }

  &_right {
    p {
      margin: 0;
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      color: #2C2C2C;
    }

    span {
      font-size: 14px;
      line-height: 17px;
      font-weight: 400;
      color: #959595;
    }
  }
}

.placements {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 14px;


  &_item {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 300px;

    &_left,
    &_right {
      display: flex;
      align-items: center;
      gap: 6px;

      span {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
      }
    }

    &_left {
      span {
        color: #475467;
      }
    }

    &_right {
      span {
        color: #475467;
      }

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
}

.suffixActive {

  svg {

    transform: rotate(180deg) !important;
  }
}

.bottomCall {
  display: none;

  @media screen and (max-width: 991px) {
    display: flex;
  }
}

.fade_in {
  max-height: 250px;
  transition: max-height .5s ease;
}

.fade_out {
  max-height: 0;
  transition: max-height .5s ease;
}



.with_animation {
  overflow: hidden;
  transition: max-height .5s ease;
}

.about_complex_text {
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 6;
  max-height: calc(20px * 6);
}

.about_complex_text::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  pointer-events: none;
}

.details {
  @apply overflow-visible;
}

.details[open] .about_complex_text {
  -webkit-line-clamp: unset;
  max-height: unset;
}

.details[open] .about_complex_text::after {
  @apply hidden;
}

.brand_link {
  @apply hidden mt-3;
}

.details[open] .brand_link {
  @apply block;
}

.details[open] .details_all {
  @apply hidden;
}

.arrow_icon {
  transition: transform 0.3s;
}


.map_fullscreen {
  @apply w-full h-full relative z-[1] #{!important};

}

.map_container,
.map_fullscreen {
  :global {
    .leaflet-control-zoom {
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2) !important;
      @apply flex flex-col items-center w-9 h-[76px] rounded-xl p-[6px] gap-4 border border-solid border-gray-200 bg-white justify-center #{!important};
    }

    .leaflet-control-zoom-in,
    .leaflet-control-zoom-out {
      @apply w-6 h-6 flex-1 text-3xl flex justify-center items-center #{!important};

      >span {
        @apply font-medium text-[#344054] #{!important};
      }
    }

    .leaflet-touch,
    .leaflet-bar a {
      @apply w-0 h-0 leading-[0] #{!important};
    }
  }
}

.map_fullscreen {
  :global {
    .leaflet-control-container {
      @apply absolute top-[41%] right-5 #{!important};
    }

  }
}